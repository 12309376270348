<template>
  <div class="all_main_wrap">
    <!-- <ImportExcelList :get-import-data="getImportData" /> -->
    <ImportExcelList :get-import-data="getImportData">
      <template #btn>
        <a-button @click="onToLead()"><a-icon type="upload" />导入</a-button>
      </template> 
    </ImportExcelList>
    <div>
        {{listData}}
    </div>
    <a-upload
      :file-list="fileList"
      :remove="handleRemove"
      :beforeUpload="beforeUpload"
    >
      <a-button> <a-icon type="upload" />上传导入文件</a-button>
    </a-upload>
    <a-button type="primary" @click="onToLead()">开始导入</a-button>
  </div>
</template>

<script>
import ImportExcelList from "@/components/ImportExcelList.vue";
export default {
  // 可用组件的哈希表
  components: { ImportExcelList },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
        listData:[],
        fileList: []
    };
  },
  // 事件处理器
  methods: {
    getImportData(data) {
      this.listData = data
    },
    // 开始导入
    onToLead () {
        this.$ajax({
          url: '/hxclass-management/exam/tripartite/import/certifinfo',
          method: 'post',
          params: {
            file: this.fileList[0],
          }
        }).then(res => {
          // if (res.code == 1) {
          //   this.$success({
          //     title: res.msg,
          //   });
          // } else {
          //   this.$error({
          //     title: res.msg,
          //   });
          // }
        })
    },
    // 上传封面之前 格式与大小校验
    beforeUpload (file) {
      const format = /\.(xlsx)(\?.*)?$/
      if (!format.test(file.name)) {
        this.$message.warning('文件格式只支持 xlsx ！')
        this.loading = false
        return false
      }
      // console.log(file)
      this.fileList = [file]
      return false
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    }
  },
};
</script>

<style lang="less" scoped>
</style>
